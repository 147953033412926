import React, {useEffect, useState} from 'react';
import ReactDOM from "react-dom";
import {loadProjectTeam} from "../../api/travel";
import classes from "./css/travel.module.css";
import FlightDetailsForm from "./FlightDetailsForm";

function TravelInformation({projectId}) {
  const [team, setTeam] = useState([]);
  const [project, setProject] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    loadProjectTeam(projectId)
      .then(data => {
        console.log({data});
        setTeam(data.team);
        setProject(data.project);
      })
  }, [])

  const NamePlate = ({member}) => {
    let highlight = ''
    if (selected && selected.id === member.id) {
      highlight = classes.selected;
    }

    return (
      <div className={[classes.namePlate, classes.unselectable, highlight].join(' ')}
           onClick={() => setSelected(member)}>{member.name}</div>
    )
  }

  const TeamList = () => {
    console.log(`TeamList: ${team.length}`)
    if (team.length > 0) {
      return team.map(member => {
        return (
          <NamePlate member={member} key={member.id}/>
        )
      })
    } else {
      return <div>Loading...</div>
    }
  }

  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-3'}>
          <TeamList/>
        </div>
        <div className={[classes.travelInfo, 'col-9'].join(' ')}>
          {selected ?
            <FlightDetailsForm selected={selected} project={project}/>
            :
            <div className={classes.infoMessageContainer}>
              <div className={classes.infoMessage}>Select a team member to manage their travel arrangements</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default TravelInformation;

if (document.getElementById('travel-information')) {
  const element = document.getElementById('travel-information');
  ReactDOM.render(<TravelInformation {...element.dataset} />, element);
}
