import {useEffect, useState} from "react";
import axios from "../api/axiosConfig";
import {useDispatch} from "react-redux";

const useAxios = (initLoading) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initLoading);
  const [controller, setController] = useState(null);

  const axiosRequest = async (configObj, reducer = null) => {
    const {
      method,
      url,
      requestConfig = {}
    } = configObj;

    try {
      setLoading(true);
      setError(null);
      setController(new AbortController());

      const res = await axios[method.toLowerCase()](url, requestConfig);
      setResponse(res.data);

      if (reducer) {
        dispatch(reducer(res));
      }
    } catch (err) {
      console.log({'Response Error': err});
      setError(err.message + ' - '
        + err.response.statusText
        + (err.response.data.message ? ":\n" + err.response.data.message : '')
        + (err.response.data.errors ? Object.values(err.response.data.errors).map(item => {
          console.log({item});
          return `\n* ${item[0]}`
        }) : '')
      );
    } finally {
      setLoading(false);
    }
  }

  function clearAxiosError() {
    setError(null);
  }

  useEffect(() => {
    return () => controller && controller.abort();
  }, [controller]);

  return {response, error, loading, axiosRequest, clearAxiosError};
}

export default useAxios;