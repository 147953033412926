import React from "react";
import {Tooltip} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useDateRange from "./hooks/useDateRange";
import classes from "./styles/accommodation.module.css";

export function ListOccupants({uuid, data, onDrag, setShowEdit}) {

  return !data ? null : data.map(member => {
    const lodging = member.lodgings.filter((item) => item.uuid === uuid)
    const dates = useDateRange();
    const role = member.role === 'supervisor' ? classes.supervisor : classes.fireguard;
    const roleEditButton = member.role === 'supervisor' ? classes.editSupervisor : classes.editFireguard;

    return (
      <li
        key={member.id}
        className={["noselect ui-state-default", role].join(' ')}
        draggable
        onDragStart={e => onDrag(e, member)}
      >
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', lineHeight: '100%'}}>
          <div>
            <div>
              {member.name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {return g1.toUpperCase() + g2.toLowerCase();})}
              <span style={{float: 'right'}}>
                <Tooltip title="Edit Dates">
                  <CalendarMonthIcon
                    className={roleEditButton}
                    sx={{fontSize: 24, cursor: 'pointer', mx: .1}}
                    onClick={() => setShowEdit({show: true, occupant: member})}
                  />
                </Tooltip>
              </span>
            </div>
            {
              lodging.map((item, index) => {
                return item.active
                  ? (
                    <div key={index}>
                      {item.room &&
                        <small><strong>#{item.room} - </strong></small>
                      }
                      <small>{dates.formatDateRange(item.arrive, item.depart)}</small>
                    </div>
                  )
                  : null;
              })
            }
          </div>
        </div>
      </li>
    );
  })
}