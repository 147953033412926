import ReactDOM from "react-dom";
import React from "react";
import TravelInformation from "./react-src/components/Travel/TravelInformation";
import Accommodation from "./react-src/components/accommodation/Accommodation";
import {Provider} from "react-redux";
import accommodationStore from "./react-src/components/accommodation/redux/store";

window.renderTravelInformation = function () {
  if (document.getElementById('travel-information')) {
    const element = document.getElementById('travel-information');
    ReactDOM.render(<TravelInformation {...element.dataset} />, element);
  }
}

window.renderAccommodation = function () {
  if (document.getElementById('accommodation')) {
    const element = document.getElementById('accommodation');
    ReactDOM.render(
      <Provider store={accommodationStore}>
        <Accommodation {...element.dataset} />
      </Provider>,
      element);

    $('#ajaxModal').bind('hide.bs.modal.accommodation', function() {onHideModal(element)});
  }
}

function onHideModal (element) {
  ReactDOM.unmountComponentAtNode(element);
  $('#ajaxModal').unbind('hide.bs.modal.accommodation');
}