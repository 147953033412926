import React from "react";
import classes from './styles/accommodation.module.css'
import {useSelector} from "react-redux";
import useDateRange from "./hooks/useDateRange";
import {Tooltip} from "@mui/material";

export function TeamList({data, onDrag}) {
  const accommodations = useSelector(state => state.accommodation.lodgings);
  const dates = useDateRange();

  function checkStatus(member) {
    if (member.lodgings.length === 0) {
      return {
        glow: classes.glowGrey,
        tooltip: "No accommodation allocated"
      };
    }

    let details = {};
    let clash = false;
    member.lodgings.forEach((alpha, alphaIndex) => {
      if (!clash) {
        member.lodgings.forEach((bravo, bravoIndex) => {
          if (alphaIndex !== bravoIndex && alpha.active && bravo.active) {
            if (dates.overlaps(alpha.arrive, alpha.depart, bravo.arrive, bravo.depart)) {
              details = {alpha: alpha, bravo: bravo};
              clash = true;
            }
          }
        });
      }
    });

    if (clash) {
      const lodgingAlpha = accommodations.find(item => item.uuid === details.alpha.uuid);
      const lodgingBravo = accommodations.find(item => item.uuid === details.bravo.uuid);
      let first = lodgingAlpha;
      let second = lodgingBravo;
      if(dates.isAfter(details.alpha.arrive, details.alpha.depart, details.bravo.arrive, details.bravo.depart)) {
        first = lodgingBravo;
        second = lodgingAlpha;
      }

      return {
        glow: classes.glowRed,
        tooltip: `Allocated accommodation dates overlap between leaving ${first.title} and arriving at ${second.title}`
      };
    }

    let covered = true;
    accommodations.forEach((accommodation) => {
      const lodgings = member.lodgings.filter(lodging => lodging.uuid === accommodation.uuid);
      if (covered && lodgings.length > 0) {
        lodgings.forEach(lodging => {
          if (!dates.engulf(accommodation.from, accommodation.to, lodging.arrive, lodging.depart)) {
            details = {...accommodation};
            covered = false;
          }
        })
      }
    });

    if (!covered) {
      return {
        glow: classes.glowRed,
        tooltip: `The allocated dates at ${details.title} are not covered by the availability of the accommodation`
      };
    }

    let incomplete;
    member.assignments.forEach((assignment, index) => {
      const lodgingDates = member.lodgings
        .filter(lodging => lodging.active)
        .map(item => {
          return {start: item.arrive, end: item.depart}
        });
      const diff = dates.difference(assignment.start, assignment.finish, lodgingDates);
      if (diff.length > 0) {
        incomplete = true;
        details = {index, diff};
      }
    })

    if (incomplete) {
      return {
        glow: classes.glowYellow,
        tooltip: `Assignment #${details.index + 1} dates are not fully covered between ${details.diff[0].toFormat('LLL dd, yyyy')}`
      };
    }

    return {
      glow: classes.glowGreen,
      tooltip: "Accommodation is all set"
    };
  }

  return !data ? null : data.map(member => {
    const status = checkStatus(member);
    const role = member.role === 'supervisor' ? classes.supervisor : classes.fireguard;
    return (
      <li
        key={member.id}
        className={["noselect ui-state-default", role].join(' ')}
        draggable
        onDragStart={e => onDrag(e, member)}
      >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {member.name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {return g1.toUpperCase() + g2.toLowerCase();})}
          <Tooltip title={status.tooltip} className={classes.blobHolder}>
            <div className={[classes.blob, status.glow].join(' ')}></div>
          </Tooltip>
        </div>
      </li>
    );
  })
}
