import React from "react"
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import classes from "./css/ModalForm.module.css";

function ModalImage(props) {
  const {onClose, src, download, caption} = props;

  return (
    <div className={[classes.modal, "noselect"].join(' ')}>
      <div
        className={classes.modalImageClose}
      >
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => onClose(false)}
        >
          <CloseIcon fontSize="inherit"/>
        </IconButton>
      </div>

      <div className="d-flex justify-content-center" style={{marginTop: '100px'}}>
        <a href={download}>
          <img
            className="modal-content modal-image"
            src={src}
            title="Click to download this image"
            alt=""
          />
        </a>
      </div>
      <div id="caption">
        {caption}
      </div>
    </div>
  )
}

export default ModalImage;