import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TextInput from "../../ui/TextInput";
import classes from "../../ui/css/ModalForm.module.css";
import TextAreaInput from "../../ui/TextAreaInput";
import {addAddress} from "./redux/accommodationSlice";
import uuid from "react-uuid";
import useAxios from "../../api/useAxios";
import axios from "../../api/axiosConfig";
import ModalForm from "../../ui/ModalForm";
import {AddressList} from "./AddressList";

const newAddress = {
  title: '',
  address: '',
  contact: '',
  email: '',
  phone: ''
};

function LodgingForm({onClose}) {
  const PersonnelApi = useAxios();

  const project = useSelector(state => state.accommodation.project);
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    ...newAddress,
    location: project.location,
    occupants: [],
    cost: 0,
    currency: '',
    from: project.arrive,
    to: project.depart,
    uuid: uuid()});
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if(!PersonnelApi.response) {
      return;
    }

    dispatch(addAddress(details));
    onClose();
  }, [PersonnelApi.response]);

  function onSelect() {
    PersonnelApi.axiosRequest({
      axiosInstance: axios,
      method: 'post',
      url: `/en/axios/projects/accommodation/lodgings/${project.id}`,
      requestConfig: details
    }).then();
  }

  function onCloseSearch(address) {
    if(address) {
      setDetails({...details, ...address});
    }
    setShowSearch(false);
  }

  return (
    <>
      <div className={classes.modalBody}>
        {PersonnelApi.error && (
          <div className="alert alert-danger alert-block">
            <button type="button" className="close" onClick={PersonnelApi.clearAxiosError}>×</button>
            <span style={{whiteSpace: "pre-line"}}>{PersonnelApi.error}</span>
          </div>
        )}
        <TextInput
          label="Title"
          name="title"
          id="title"
          onChange={(value) => setDetails({...details, title: value})}
          value={details.title}
        />
        <TextAreaInput
          label="Address"
          name="address"
          id="address"
          rows="4"
          onChange={(value) => setDetails({...details, address: value})}
          value={details.address}
        />
        <TextInput
          label="Contact"
          name="contact"
          id="contact"
          onChange={(value) => setDetails({...details, contact: value})}
          value={details.contact}
        />
        <TextInput
          label="Email"
          name="email"
          id="email"
          onChange={(value) => setDetails({...details, email: value})}
          value={details.email}
        />
        <TextInput
          label="Phone"
          name="phone"
          id="phone"
          onChange={(value) => setDetails({...details, phone: value})}
          value={details.phone}
        />
      </div>
      <div className={classes.modalFooter}>
          <button onClick={() => setShowSearch(true)} className="btn btn-info mb-3 mr-3">
            Search
          </button>
        {showSearch && (
          <ModalForm
            heading="Find Address"
            onClose={() => setShowSearch(false)}
          >
            <AddressList onClose={onCloseSearch}/>
          </ModalForm>
        )}
        <div className="float-right">
          <button onClick={onSelect} className="btn btn-primary mb-3">
            Select
          </button>
        </div>
      </div>
    </>
  );
}

export default LodgingForm;