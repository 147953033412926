import React, {useEffect} from "react";
import {TeamList} from "./TeamList";
import {useDispatch, useSelector} from "react-redux";
import {drag, drop} from "./redux/accommodationSlice";
import classes from "./styles/accommodation.module.css";
import {OccupancyList} from "./OccupancyList";
import axios from "../../api/axiosConfig";
import useAxios from "../../api/useAxios";
import useDateRange from "./hooks/useDateRange";

export function ListBox({uuid, title, data, sx}) {
  const PersonnelApi = useAxios();
  const dates = useDateRange();

  const dispatch = useDispatch();
  const dragItem = useSelector(state => state.accommodation.dragItem);
  const project = useSelector(state => state.accommodation.project);

  useEffect(() => {
    if (PersonnelApi.error) {
      alert(PersonnelApi.error);
    }
  }, [PersonnelApi.error]);

  function handleDragOver(event) {
    if ((!uuid && dragItem.from) || (dragItem.item && !data.find(item => {
      return item.id === dragItem.item.id
    }))) {
      event.preventDefault();
    }
  }

  function handleDrop(event) {
    event.preventDefault();

    if (dragItem.item) {
      let lodgings = dragItem.item.lodgings;

      if (uuid) {
        const newLodging = dragItem.item.assignments.map((item, index) => {
          // ToDo: Figure out how these values can be improved to allow for any date clashes
          let active = true;
          let arrive = dates.minus(item.start, item.travel_out);
          let depart = dates.plus(item.finish, item.travel_home);

          if(dragItem.from) {
            const from = lodgings.filter(lodging => lodging.uuid === dragItem.from);
            active = from[index].active;
            arrive = from[index].arrive;
            depart = from[index].depart;
          }

          return {
            uuid: uuid,
            active: active,
            arrive: arrive,
            depart: depart
          }
        });

        lodgings = [...lodgings, ...newLodging];
      }

      if (dragItem.from) {
        lodgings = lodgings.filter(lodging => {
          return lodging.uuid !== dragItem.from;
        });
      }

      const requestItem = {
        ...dragItem.item, lodgings
      }

      const requestConfig = {...dragItem, item: requestItem, to: uuid};

      PersonnelApi.axiosRequest({
        axiosInstance: axios,
        method: 'post',
        url: `/en/axios/projects/accommodation/occupants/${project.id}`,
        requestConfig: requestConfig
      }).then();

      dispatch(drop(requestConfig));
    }
  }

  function handleDrag(event, item) {
    const occupant = {...item}
    dispatch(drag({item: occupant, from: uuid}))
  }

  return (
    <div className={classes.listBox}>
      <div className={[classes.listHeader, "noselect"].join(' ')}>
        {title}
      </div>
      <ul
        className={classes.sortableList}
        style={sx}
        onDragOver={event => handleDragOver(event)}
        onDrop={event => handleDrop(event)}
        onDragEnd={() => dispatch(drag({}))}
      >
        {
          !uuid
            ? <TeamList data={data} onDrag={handleDrag}/>
            : <OccupancyList uuid={uuid} data={data} onDrag={handleDrag}/>
        }
      </ul>
    </div>
  );
}