import axios from "axios";
import {axiosError} from "./axiosError";

export async function loadProjectTeam(projectId) {
  try {
    const response = await axios.get(
      `/en/axios/projects/travel/${projectId}`);
    return response.data;
  } catch (error) {
    axiosError(error);
  }
}

export async function saveFlightDetails(projectId, data) {
  try {
    const response = await axios.put(
      `/en/axios/projects/travel/`,
      {
        project: projectId,
        flight: data
      });
    return response.data;
  } catch (error) {
    axiosError(error);
  }
}