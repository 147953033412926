require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import './render-react';

import 'admin-lte/plugins/select2/js/select2';
import 'admin-lte/plugins/select2/css/select2.css';

import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/datepicker.js';
