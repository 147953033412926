import React, {useEffect, useState} from "react";
import useAxios from "../../api/useAxios"
import {useSelector} from "react-redux";
import {load} from "./redux/accommodationSlice";
import {ListBox} from "./ListBox";
import LodgingForm from "./LodgingForm";
import ModalForm from "../../ui/ModalForm";
import {AddressPanel} from "./AddressPanel";

function Accommodation({projectId}) {
  const PersonnelApi = useAxios(true);

  const [showAddressModal, setShowAddressModal] = useState(false);

  const lodgings = useSelector(state => state.accommodation.lodgings);
  const team = useSelector(state => state.accommodation.team);

  useEffect(() => {
    PersonnelApi.axiosRequest(
      {
        method: 'get',
        url: `/en/axios/projects/accommodation/${projectId}`
      },
      response => load(response.data)
    ).then();
  }, []);

  if (PersonnelApi.loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{maxHeight: '450px', height: '450px'}}
      >
        <h5>Loading...</h5>
      </div>
    );

  if (PersonnelApi.error)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{maxHeight: '450px', height: '450px'}}
      >
        <div>
          <h5>System Error...</h5>
          <p>{PersonnelApi.error}</p>
        </div>
      </div>
    );

  return (
    <>
      <div className="row" style={{maxHeight: '450px', height: '450px'}}>
        <div className="col col-md-4 h-100">
          <ListBox title={<strong>Team Members</strong>} data={team} sx={{height: '400px'}}/>
        </div>
        <div className="col col-md-8 h-100" style={{overflowY: 'scroll'}}>
          {
            lodgings.length > 0 ? (
              <div className="row">
                <AddressPanel/>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">
                <h5 className="p-3" style={{background: '#eee', color: '#888', borderRadius: '5px'}}>
                  Add an address to get started</h5>
              </div>
            )
          }
        </div>
      </div>
      <div className="modal-footer pb-0">
        <button
          className="btn btn-primary"
          onClick={() => setShowAddressModal(true)}
        >
          <i className="fas fa-house-user mr-1"></i>
          Add Address
        </button>
        {showAddressModal && (
          <ModalForm
            heading="Add Address"
            onClose={() => setShowAddressModal(false)}
          >
            <LodgingForm
              onClose={() => setShowAddressModal(false)}
            />
          </ModalForm>
        )}
        <a
          className="btn btn-primary"
          href={`/en/axios/projects/accommodation/export/${projectId}`}
        >
          <i className="far fa-file-pdf mr-1"></i>
          Export
        </a>
      </div>
    </>
  )
}

export default Accommodation;
