import React, {useState} from "react";
import ModalForm from "../../ui/ModalForm";
import {ListOccupants} from "./ListOccupants";
import {EditOccupancyModalContent} from "./EditOccupancyModalContent";

export function OccupancyList({uuid, data, onDrag}) {
  const [edit, setEdit] = useState({show: false, occupant: null});

  return (
    <>
      <ListOccupants uuid={uuid} data={data} onDrag={onDrag} setShowEdit={setEdit}/>
      {edit.show && (
        <ModalForm
          heading={`Edit dates for ${edit.occupant.name.replace(/(\w)(\w*)/g, function (g0, g1, g2) {return g1.toUpperCase() + g2.toLowerCase();})}`}
          onClose={() => setEdit({show: false, occupant: null})}
        >
          <EditOccupancyModalContent
            uuid={uuid}
            occupant={edit.occupant}
            onClose={() => setEdit({show: false, occupant: null})}
          />
        </ModalForm>
      )}
    </>
  );
}
