import React, {useState} from "react";
import Label from "./Label";

function DateInput(props) {
  const {id, label, name, value, onChange, error} = props;

  const [storedValue, setValue] = useState(value ? value.split(' ')[0] : '')

  function onChangeHandler(event) {
    setValue(event.target.value);
    onChange && onChange(event.target.value)
  }

  return (
    <div className="form-group row">
      <Label htmlFor={id} label={label}/>
      <div className="col">
        <input
          id={id}
          type="date"
          className={["form-control", error && "is-invalid"].join(' ')}
          name={name}
          autoFocus
          placeholder="dd/mm/yyyy"
          value={storedValue}
          onChange={onChangeHandler}
        />
        {error &&
          <div className="invalid-feedback">
            <strong>{error}</strong>
          </div>
        }
      </div>
    </div>
  )
}

export default DateInput;