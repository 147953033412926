import React from "react";
import useDateRange from "./hooks/useDateRange";
import {DateTime} from "luxon";

export function EditAssignmentOccupancy({index, assignment, lodging, onChange}) {
  const assignmentDates = useDateRange(
    assignment.start,
    assignment.finish,
    assignment.travel_out,
    assignment.travel_home
  );

  return (
    <div className="container py-2 px-3 mb-3" style={{border: "1px solid #ddd", borderRadius: "5px"}}>
      <div className="row noselect align-items-center form-row">
        <input
          type="checkbox"
          id={`checkbox-${index}`}
          className="mr-2"
          checked={lodging.active}
          onChange={() => onChange(index, {...lodging, active: !lodging.active})}
        />
        <label className="form-check-label" htmlFor={`checkbox-${index}`}>
          <strong>
            Assignment {index + 1}: <small>{assignmentDates.formatTravelDates()}</small>
          </strong>
        </label>
      </div>
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-3">
          Room/Cabin:
        </label>
        <input
          className="form-control col mb-3"
          type="text"
          value={lodging.room}
          onChange={(e) => onChange(index, {...lodging, room: e.target.value})}
          disabled={!lodging.active}
        />
      </div>
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-3">
          Arrive:
        </label>
        <input
          className="form-control col mb-3"
          type="date"
          value={DateTime.fromISO(lodging.arrive).toISODate()}
          min={assignmentDates.arrive.toISODate()}
          max={assignmentDates.depart.toISODate()}
          onChange={(e) => onChange(index, {...lodging, arrive: e.target.value})}
          disabled={!lodging.active}
        />
      </div>
      <div className="row">
        <label className="text-right col-form-label whitespace-no-wrap mx-2 col-3">Depart:</label>
        <input
          className="form-control col"
          type="date"
          value={DateTime.fromISO(lodging.depart).toISODate()}
          min={assignmentDates.arrive.toISODate()}
          max={assignmentDates.depart.toISODate()}
          onChange={(e) => onChange(index, {...lodging, depart: e.target.value})}
          disabled={!lodging.active}
        />
      </div>
    </div>
  );
}