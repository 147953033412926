import classes from "./css/ModalForm.module.css";
import CloseIcon from "@mui/icons-material/Close";
import React, {useRef} from "react";

function ModalForm(props) {
  const ref = useRef();

  return (
    <div className={classes.modal}>
      <div ref={ref} className={classes.modalContent} style={props.style}>
        <div className={classes.close}>
          <CloseIcon sx={{fontSize: 30}} onClick={props.onClose}/>
        </div>
        <div className={classes.modalHeader}>
          <div className={classes.heading}>
            {props.heading}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default ModalForm;
