import React, {useState} from "react";
import classes from "../../css/ganttChart/AssignmentsForm.module.css";
import DateInput from "../../ui/DateInput";
import TextInput from "../../ui/TextInput";
import {DateTime} from "luxon";
import {saveFlightDetails} from "../../api/travel";

function FlightDetailsForm({selected, project}) {
  const [departDate, setDepartDate] = useState(DateTime.fromISO(selected.depart_date).toISODate());
  const [arriveDate, setArriveDate] = useState(DateTime.fromISO(selected.arrive_date).toISODate());
  const [returnDate, setReturnDate] = useState(DateTime.fromISO(selected.return_date).toISODate());

  const [details, setDetails] = useState(selected);

  function onSubmit() {
    saveFlightDetails(project.id, details)
      .then(data => {
        console.log({data});
      })
  }

  return (
    <div>
      <TextInput
        readonly={true}
        label="Gender"
        id="gender"
        name="gender"
        value={selected.gender}
      />
      <TextInput
        readonly={true}
        label="Date of Birth"
        id="birthday"
        name="birthday"
        value={selected.birthday}
      />
      <DateInput
        label="Departure Date"
        id='depart_date'
        date={details.depart_date}
        onChange={(value) => setDetails({...details, depart_date: value})}
      />
      <TextInput
        label="Departure City"
        id="depart_from"
        name="depart_from"
        value={details.depart_from}
        onChange={(value) => setDetails({...details, depart_from: value})}
      />
      <DateInput
        label="Arrival Date"
        id="arrive_date"
        date={details.arrive_date}
        onChange={(value) => setDetails({...details, arrive_date: value})}
      />
      <TextInput
        label="Arrival City"
        id="depart_to"
        name="depart_to"
        value={details.depart_to}
        onChange={(value) => setDetails({...details, depart_to: value})}
      />
      <DateInput
        label="Return Date"
        id="return_date"
        date={details.return_date}
        onChange={(value) => setDetails({...details, return_date: value})}
      />
      <TextInput
        label="From City"
        id="return_from"
        name="return_from"
        value={details.return_from}
        onChange={(value) => setDetails({...details, return_from: value})}
      />
      <TextInput
        label="To City"
        id="return_to"
        name="return_to"
        value={details.return_to}
        onChange={(value) => setDetails({...details, return_to: value})}
      />
      <div className={classes.modalFooter}>
        <div className="float-right">
          <button onClick={onSubmit} className="btn btn-primary mb-3 mr-3">Save</button>
        </div>
      </div>

    </div>
  )
}

export default FlightDetailsForm;