import {useSelector} from "react-redux";
import React, {useState} from "react";
import classes from "../../ui/css/ui.module.css";

export function AddressList({onClose}) {
  const [search, setSearch] = useState('');
  const addresses = useSelector(state => state.accommodation.addresses);
  const lodgings = useSelector(state => state.accommodation.lodgings);

  const filter = lodgings.map(lodging => lodging.uuid);
  const filtered = addresses.filter(address => !filter.includes(address.uuid));

  return (
    <div style={{padding: '5px', height: '300px', overflow: 'auto'}}>
      <input
        className="form-control col mb-3"
        type="text"
        placeholder="Start typing to search for an address..."
        autoFocus={true}
        onChange={(e) => setSearch(e.target.value)}
      />
      {
        filtered.map(details => {
          if (details.title.toLowerCase().includes(search) || details.address.toLowerCase().includes(search)) {
            return (
              <div
                className={classes.hover}
                onClick={() => onClose(details)}
                key={details.uuid}
              >
                <h5><strong>{details.title}</strong></h5>
                <p>{details.address}</p>
              </div>
            );
          }

          return <></>;
        })
      }
    </div>
  );
}