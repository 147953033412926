import {EditAssignmentOccupancy} from "./EditAssignmentOccupancy";
import classes from "../../ui/css/ModalForm.module.css";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useDateRange from "./hooks/useDateRange";
import useAxios from "../../api/useAxios";
import {updateOccupancy} from "./redux/accommodationSlice";

export function EditOccupancyModalContent({uuid, occupant, onClose}) {
  const project = useSelector(state => state.accommodation.project);
  const lodgings = useSelector(state => state.accommodation.lodgings);
  const lodging = lodgings.find((item) => item.uuid === uuid);
  const dates = useDateRange(lodging.from, lodging.to);
  const PersonnelApi = useAxios(true);
  const dispatch = useDispatch();

  const group = occupant.lodgings.filter(item => item.uuid === uuid);
  const [formData, setFormData] = useState(group);

  useEffect(() => {
    if (PersonnelApi.response) {
      const update = occupant.lodgings.filter(item => item.uuid !== uuid);
      dispatch(updateOccupancy({...occupant, lodgings: [...update, ...formData]}));
      onClose();
    }
  }, [PersonnelApi.response]);

  function onSave() {
    PersonnelApi.axiosRequest({
      axiosInstance: axios,
      method: 'put',
      url: `/en/axios/projects/accommodation/occupants/${project.id}`,
      requestConfig: {lodgings: {...formData}, uuid: uuid, user_id: occupant.id}
    }).then();
  }

  function handleChange(index, data) {
    const update = [...formData];
    update[index] = data;
    setFormData(update);
  }

  return (
    <div className="container p-3">
      {PersonnelApi.error && (
        <div className="alert alert-danger alert-block">
          <button type="button" className="close" onClick={PersonnelApi.clearAxiosError}>×</button>
          <span style={{whiteSpace: "pre-line"}}>{PersonnelApi.error}</span>
        </div>
      )}
      <h5>{lodging.title} available {dates.formatDates()}</h5>
      {
        occupant.assignments.map((assignment, index) => {
          return (
            <EditAssignmentOccupancy
              key={assignment.id}
              index={index}
              assignment={assignment}
              lodging={formData[index]}
              onChange={handleChange}
            />
          );
        })
      }
      <div className={classes.modalFooter}>
        <div className="float-right">
          <button onClick={onSave} className="btn btn-primary mb-3">
            Update
          </button>
        </div>
      </div>
    </div>
  );
}