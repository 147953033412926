import {createSlice} from '@reduxjs/toolkit'
import {DateTime} from "luxon";

export const accommodationSlice = createSlice({
  name: 'accommodation',

  initialState: {
    addresses: [],
    lodgings: [],
    project: {},
    team: [],
    dragItem: {}
  },

  reducers: {
    load: (state, action) => {
      const {addresses, project, team, lodgings} = action.payload;
      state.addresses = addresses;
      state.project = {
        ...project,
        arrive: DateTime.fromISO(project.start)
          .minus({days: +project.travel_out})
          .toISODate(),
        depart: DateTime.fromISO(project.finish)
          .plus({days: +project.travel_home})
          .toISODate()
      };
      state.team = team;
      state.lodgings = lodgings.map(lodging => {
        return !lodging.occupants
          ? {...lodging, occupants: []}
          : lodging;
      });
    },

    drag: (state, action) => {
      state.dragItem = action.payload;
    },

    drop: (state, action) => {
      state.dragItem = {};

      // Update the team member's lodgings
      const teamIndex = state.team.findIndex(member => member.id === action.payload.item.id);
      state.team[teamIndex] = action.payload.item;

      // Remove the team member from the old lodging if necessary
      const from = state.lodgings.find(lodging => lodging.uuid === action.payload.from)
      if (from) {
        from.occupants = from.occupants.filter(item => item.id !== action.payload.item.id);
      }

      // Update any existing lodgings with the updated team member details
      state.lodgings.forEach(lodging => {
        lodging.occupants = lodging.occupants.map(occupant => {
          return occupant.id === action.payload.item.id
            ? {...occupant, ...state.team[teamIndex]}
            : occupant;
        })
      });

      // Add the updated team member to a new lodging if necessary
      const to = state.lodgings.find(lodging => lodging.uuid === action.payload.to);
      if (to) {
        if (!to.occupants.find(item => item.id === action.payload.item.id)) {
          to.occupants = [...to.occupants, state.team[teamIndex]];
        }
      }
    },

    addAddress: (state, action) => {
      const lodging = state.addresses.find((item) => item.uuid === action.payload.uuid)
      if (!lodging) {
        state.addresses.push({
          address: action.payload.address,
          contact: action.payload.contact,
          email: action.payload.email,
          location: action.payload.location,
          phone: action.payload.phone,
          title: action.payload.title,
          uuid: action.payload.uuid
        })
      } else {
        lodging.title = action.payload.title;
        lodging.address = action.payload.address;
        lodging.contact = action.payload.contact;
        lodging.email = action.payload.email;
        lodging.phone = action.payload.phone;
      }
      state.lodgings = [...state.lodgings, action.payload];
    },

    removeAddress: (state, action) => {
      state.lodgings = state.lodgings.filter(item => item.uuid !== action.payload);
    },

    updateLodging: (state, action) => {
      const index = state.lodgings.findIndex((item) => item.uuid === action.payload.uuid)
      state.lodgings[index] = {...state.lodgings[index], ...action.payload};
    },

    updateOccupancy: (state, action) => {
      // Update the team member's lodgings
      const teamIndex = state.team.findIndex(member => member.id === action.payload.id);
      state.team[teamIndex] = action.payload;

      // Update any existing lodgings with the updated team member details
      state.lodgings.forEach(lodging => {
        lodging.occupants = lodging.occupants.map(occupant => {
          return occupant.id === action.payload.id
            ? {...occupant, ...state.team[teamIndex]}
            : occupant;
        })
      });
    },

    addAccommodationNote: (state, action) => {
      const {id, user_name, note_text, filename, path, scan, created_at} = action.payload;
      const lodging = state.lodgings.find(item => item.uuid = action.payload.uuid);

      lodging.notes = [
        ...lodging.notes,
        {id, user_name, note_text, filename, path, scan, created_at}
      ];
    },

    updateAccommodationNote: (state, action) => {
      const {id, note_text, filename, path, scan} = action.payload;
      const lodging = state.lodgings.find(item => item.uuid = action.payload.uuid);
      const note = lodging.notes.find(item => item.id === id);

      if (note) {
        note.note_text = note_text;
        note.filename = filename;
        note.path = path;
        note.scan = scan;
      }
    },

    deleteAccommodationNote: (state, action) => {
      const lodging = state.lodgings.find(item => item.uuid = action.payload.uuid);
      lodging.notes = lodging.notes.filter(item => item.id !== action.payload.id);
    }
  }
})

export const {
  load,
  drop,
  drag,
  addAddress,
  removeAddress,
  updateLodging,
  updateOccupancy,
  addAccommodationNote,
  updateAccommodationNote,
  deleteAccommodationNote
} = accommodationSlice.actions

export default accommodationSlice.reducer;
