// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WvKI-5FBxlX9WcdFB3UY3w\\=\\={cursor:pointer;padding:4px 8px}.WvKI-5FBxlX9WcdFB3UY3w\\=\\=:hover{background:#d3d3d3}._7zSYOtvrIA7RwyhLcVgYQw\\=\\={background:#add8e6}.rdaxCskFS\\+xX\\+3gA7lHbYA\\=\\={border-left:1px solid #d3d3d3;height:600px}.IO5XNSvFrXNs4wHDtrHLAw\\=\\={align-items:center;display:flex;height:100%;justify-content:center}._7H7rHa2BBHdVY8OHtdj0aQ\\=\\={background:#add8e6;border-radius:5px;padding:8px}.wRCFD7GTS1TJTB3p8o0gHw\\=\\={-moz-user-select:none;-webkit-user-select:none;user-select:none}", "",{"version":3,"sources":["webpack://./resources/js/react-src/components/Travel/css/travel.module.css"],"names":[],"mappings":"AAAA,4BAEE,cAAe,CADf,eAEF,CAEA,kCACE,kBACF,CAEA,6BACE,kBACF,CAEA,8BACE,6BAAgC,CAChC,YACF,CAEA,4BAIE,kBAAmB,CAHnB,YAAa,CACb,WAAY,CACZ,sBAEF,CAEA,6BAEE,kBAAqB,CACrB,iBAAkB,CAFlB,WAGF,CAEA,4BACE,qBAAsB,CACtB,wBAAyB,CAEzB,gBACF","sourcesContent":[".namePlate {\n  padding: 4px 8px;\n  cursor: pointer;\n}\n\n.namePlate:hover {\n  background: lightgrey;\n}\n\n.selected {\n  background: lightblue;\n}\n\n.travelInfo {\n  border-left: 1px solid lightgrey;\n  height: 600px;\n}\n\n.infoMessageContainer {\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n}\n\n.infoMessage {\n  padding: 8px;\n  background: lightblue;\n  border-radius: 5px;\n}\n\n.unselectable {\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"namePlate": "WvKI-5FBxlX9WcdFB3UY3w==",
	"selected": "_7zSYOtvrIA7RwyhLcVgYQw==",
	"travelInfo": "rdaxCskFS+xX+3gA7lHbYA==",
	"infoMessageContainer": "IO5XNSvFrXNs4wHDtrHLAw==",
	"infoMessage": "_7H7rHa2BBHdVY8OHtdj0aQ==",
	"unselectable": "wRCFD7GTS1TJTB3p8o0gHw=="
};
export default ___CSS_LOADER_EXPORT___;
