// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sX\\+APqRd12kngnZqTsyrZA\\=\\={align-items:center;border:1px dashed #348fda;border-radius:5px;color:#aaa;display:flex;font-weight:700;height:83px;justify-content:center;padding:4px}.Q\\+8E4ikkV6pA-9d5WShQYg\\=\\={background-color:#eee;border:2px solid #348fda;color:#888}.aAeuWkRUX5EFUBgtWe4SGQ\\=\\={align-items:center;display:flex;flex-direction:column;justify-content:center}.aAeuWkRUX5EFUBgtWe4SGQ\\=\\= button{border:none;border-radius:5px;cursor:pointer;font-size:12px;font-weight:500;outline:none;padding:4px 8px}.aAeuWkRUX5EFUBgtWe4SGQ\\=\\= img{border-radius:5px;height:100%;-o-object-fit:cover;object-fit:cover;width:100%}", "",{"version":3,"sources":["webpack://./resources/js/react-src/components/NotesPanel/css/dragNdrop.module.css"],"names":[],"mappings":"AAAA,6BAEE,kBAAmB,CAInB,yBAA0B,CAC1B,iBAAkB,CAClB,UAAW,CAPX,YAAa,CAQb,eAAiB,CALjB,WAAY,CADZ,sBAAuB,CAEvB,WAKF,CAEA,6BAEE,qBAAsB,CADtB,wBAAyB,CAEzB,UACF,CAEA,4BAEE,kBAAmB,CADnB,YAAa,CAGb,qBAAsB,CADtB,sBAEF,CAEA,mCAIE,WAAY,CAEZ,iBAAkB,CAClB,cAAe,CALf,cAAe,CACf,eAAgB,CAEhB,YAAa,CAJb,eAOF,CAEA,gCAIE,iBAAkB,CAHlB,WAAY,CAEZ,mBAAiB,CAAjB,gBAAiB,CADjB,UAGF","sourcesContent":[".dragContainer{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 83px;\r\n  padding: 4px;\r\n  border: 1px dashed #348fda;\r\n  border-radius: 5px;\r\n  color: #aaa;\r\n  font-weight: bold;\r\n}\r\n\r\n.active{\r\n  border: 2px solid #348fda;\r\n  background-color: #eee;\r\n  color: #888;\r\n}\r\n\r\n.dragArea{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.dragArea button{\r\n  padding: 4px 8px;\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n  border: none;\r\n  outline: none;\r\n  border-radius: 5px;\r\n  cursor: pointer;\r\n}\r\n\r\n.dragArea img{\r\n  height: 100%;\r\n  width: 100%;\r\n  object-fit: cover;\r\n  border-radius: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragContainer": "sX+APqRd12kngnZqTsyrZA==",
	"active": "Q+8E4ikkV6pA-9d5WShQYg==",
	"dragArea": "aAeuWkRUX5EFUBgtWe4SGQ=="
};
export default ___CSS_LOADER_EXPORT___;
