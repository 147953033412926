import React from "react";

function Label({htmlFor, label}) {
  return (
    <label htmlFor={htmlFor} className="col-md-3 col-form-label text-md-right font-weight-bold">
      {label}
    </label>
  )
}

export default Label;