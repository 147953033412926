import React, {useEffect, useState} from "react";
import Label from "./Label";

function TextAreaInput(props) {
  const {id, rows, label, name, value, onChange, error} = props;
  const [savedValue, setValue] = useState(value ?? '')

  useEffect(() => {
    if (value) {
      setValue(value)
    }
  }, [value])

  function onChangeHandler(event) {
    setValue(event.target.value);
    onChange && onChange(event.target.value)
  }

  return (
    <div className="form-group row">
      <Label htmlFor={id} label={label}/>
      <div className="col">
        <textarea
          id={id}
          name={name}
          rows={rows}
          className={["form-control", error && "is-invalid"].join(' ')}
          autoFocus
          onChange={onChangeHandler}
          value={savedValue}
        />
        {error &&
          <div className="invalid-feedback">
            <strong>{error}</strong>
          </div>
        }
      </div>
    </div>
  )
}

export default TextAreaInput;